import { fullName, getSameNameGolfersIds, golfersOfTeam, shortName } from "src/contact/Contact";
import { eventPayoutsStates, genderFromEvent, getDistanceScores, getSkinsScores, getSplitCompetitionsWithPayouts } from "src/event/Event";
import { getRoundsCompetitions, ContactScoringState, Competition, Contact, ContactPayoutState, Distance, EventBase, EventData, ReportedScore, Score, ScoresData, ScoringFormatDistance, ScoringFormatSkins, ScoringFormatTeams, Team, getEventMainCompetition, getHolesRange, getTee, isDistanceScoring, isGrossPayouts, isIndividualScoringOrBB, isNetMode, isGrossMode, isNetPayouts, isSideScoringWithPayouts, isSkinsScoring, isTeamScoringExceptBB } from "src/types/EventTypes";
import { formatCurrency, makeFriendlyString } from "src/util/utility";
import * as Scoring from '../../../scoring/scoring';

export function getScores(eventOrRoundId: string, scoresData?: ScoresData) {
    const { golferScoresMap, teamScoresMap, reportedScoresMap, reportedTeamScoresMap, distancesMap } = scoresData ?? {};
    const golferScores = golferScoresMap?.get(eventOrRoundId) ?? new Map<string, Score>();
    const teamScores = teamScoresMap?.get(eventOrRoundId) ?? new Map<string, Score>();
    const reportedScores = reportedScoresMap?.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
    const reportedTeamScores = reportedTeamScoresMap?.get(eventOrRoundId) ?? new Map<string, ReportedScore>();
    const distances = distancesMap?.get(eventOrRoundId) ?? new Map<string, Distance>();
    return { golferScores, teamScores, reportedScores, reportedTeamScores, distances };
}

export function getEventStaff(eventOrRoundId: string, eventData: EventData) {
    const { event, rounds, golfersMap, teamsMap, groupsMap, competitionsMap } = eventData;
    const golfers = golfersMap.get(eventOrRoundId) ?? new Map<string, Contact>();
    const teams = teamsMap.get(eventOrRoundId) ?? new Map<string, Team>();
    const groups = groupsMap.get(eventOrRoundId) ?? [];
    const competitions = competitionsMap.get(eventOrRoundId) ?? [];
    const mainCompetition = getEventMainCompetition(competitions);
    return { event, rounds, golfers, teams, groups, competitions, mainCompetition };
}

export function exportSideGamesData(eventOrRound: EventBase, eventData: EventData, scoresData: ScoresData) {
    const { golfers, competitions, mainCompetition } = getEventStaff(eventOrRound.id, eventData);
    const { distances } = getScores(eventOrRound.id, scoresData);
    const gender = genderFromEvent(eventOrRound);
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    const competitionsDistanceGames = competitions.filter(competition => isDistanceScoring(competition.scoring));
    const exportData: Array<Array<string | number>> = [];
    competitionsDistanceGames.forEach(comp => {
        if (!mainCompetition) {
            return;
        }
        const distanceInfo = getDistanceScores(eventOrRound, comp, golfers, distances, getTee(eventOrRound, mainCompetition, gender, undefined));
        distanceInfo.forEach(dist => {
            const firstContact: Contact = dist.contacts[0];
            const sideRow: Array<string> = [String(dist.hole + 1), comp.scoring.format === ScoringFormatDistance.closest_to_the_pin ? 'Closest to the pin' : 'Longest drive',
            dist.contacts.length === 1 ? fullName(firstContact).concat(sameNameGolfersIdsSet.has(firstContact.id) && firstContact.homeCourseOrCity ? `(${firstContact.homeCourseOrCity})` : '') : ''];
            exportData.push(sideRow);
        });
    });
    exportData.sort((a1: Array<string | number>, a2: Array<string | number>) => { return Number(a1[0]) - Number(a2[0]); });
    exportData.unshift(['Hole', 'Game', 'Winner(s)']);
    return exportData;
}

export function exportMoneyListData(eventOrRound: EventBase, eventData: EventData, scoresData: ScoresData) {
    const { golfers, teams, groups, competitions } = getEventStaff(eventOrRound.id, eventData);
    const { /*golferScores, teamScores, reportedScores, reportedTeamScores, */distances } = getScores(eventOrRound.id, scoresData);
    const [splitMainCompetitions, splitSideCompetitions] = getSplitCompetitionsWithPayouts(competitions);
    const skinsMixed = competitions.some(comp => comp.scoring.format === ScoringFormatTeams.best_ball);
    const row = [eventOrRound.teamSize > 1 ? 'Team' : 'Golfer'];
    const exportData: Array<Array<string>> = [];
    splitMainCompetitions.forEach(comp => {
        if (isNetPayouts(comp)) {
            row.push(makeFriendlyString(comp.scoring.format + ', Net', true));
        }
        if (isGrossPayouts(comp)) {
            row.push(makeFriendlyString(comp.scoring.format + ', Gross', true));
        }
    });
    splitSideCompetitions.forEach(comp => {
        if (isSideScoringWithPayouts(comp)) {
            row.push(Scoring.scoringName(comp, eventOrRound.eventGender, comp.competitionGender, skinsMixed));
        }
    });
    const scoresMap: Map<string, Array<Array<ContactScoringState>>> = new Map();
    competitions.forEach(competition => {
        const comps: Array<Competition> = new Array<Competition>();
        if (isGrossMode(competition.scoring.mode)) { comps.push(Scoring.grossCompetition(competition)); }
        if (isNetMode(competition.scoring.mode)) { comps.push(Scoring.netCompetition(competition)); }
        comps.forEach(comp => {
            const roundsCompetitions = getRoundsCompetitions(comp, competitions);
            Scoring.getFlights(comp).forEach(flight => {
                if (!scoresMap.has(comp.id + comp.scoring.mode + flight)) {
                    const roundsScores = eventData.event.type === 'multiday' ?
                        Scoring.combineCompetitionScores(roundsCompetitions, flight, scoresData.calculatedScoresMap) :
                        Scoring.getCompetitionScores(roundsCompetitions, flight, scoresData.calculatedScoresMap);
                    scoresMap.set(comp.id + comp.scoring.mode + flight, roundsScores);
                }
            });
        });
    });
    
    row.push('Total');
    exportData.push(row);
    const moneyList: ContactPayoutState[] = eventPayoutsStates(eventOrRound, competitions, scoresMap, golfers, teams, groups, new Map([[eventOrRound.id, distances]]), getSameNameGolfersIds(Array.from(golfers.values())));
    moneyList.filter(ps => ps.total > 0).forEach(ps => {
        const golferName = ps.names.join(' + ');
        const row: Array<string> = [golferName];
        splitMainCompetitions.forEach(comp => {
            if (isNetPayouts(comp)) {
                row.push('$' + (ps.awards.has(comp.id + 'net') ? formatCurrency(ps.awards.get(comp.id + 'net')!) : '0'));
            }
            if (isGrossPayouts(comp)) {
                row.push('$' + (ps.awards.has(comp.id + 'gross') ? formatCurrency(ps.awards.get(comp.id + 'gross')!) : '0'));
            }
        });
        splitSideCompetitions.forEach(comp => {
            if (isSideScoringWithPayouts(comp)) {
                row.push('$' + (ps.awards.has(comp.id) ? formatCurrency(ps.awards.get(comp.id)!) : '0'));
            }
        });
        row.push('$' + formatCurrency(ps.total));
        exportData.push(row);
    });
    return exportData;
}

export function exportCompetitionsData(eventOrRound: EventBase, eventData: EventData, scoresData: ScoresData) {
    const { golfers, teams, groups, competitions, mainCompetition } = getEventStaff(eventOrRound.id, eventData);
    const { golferScores, teamScores, reportedScores, reportedTeamScores } = getScores(eventOrRound.id, scoresData);
    const holesRange = getHolesRange(eventOrRound.holesType);
    const compData: Array<Array<string | number>> = [];
    const compHeader: Array<string> = golferScores.size > 0 ? ['Golfer'] : ['Team'];
    for (let i = holesRange.first; i < holesRange.last; i++) {
        compHeader.push('Hole#' + (i + 1));
    }
    const hasIndividualCompetition = Boolean(competitions.find(c => isIndividualScoringOrBB(c.scoring)
        || c.scoring.format === ScoringFormatSkins.skins_individual));
    const hasTeamCompetition = Boolean(competitions.find(c => isTeamScoringExceptBB(c.scoring) ||
        (c.scoring.format === ScoringFormatSkins.skins_team && mainCompetition?.scoring.format !== ScoringFormatTeams.best_ball)));
    if (teams.size > 0 && hasTeamCompetition) {
        teams.forEach((team: Team, key: string) => {
            if (!mainCompetition) {
                return;
            }
            const compRow: Array<string | number> = [];
            compRow.push('Team ' + (team.order + 1));
            const contacts = golfersOfTeam(team, golfers);
            const tees = contacts.map(c => getTee(eventOrRound, mainCompetition, c.gender, c));
            const score = team.contactIds.findIndex(id => !golferScores.has(id)) < 0 ? Scoring.bestBallGross(team.contactIds, golferScores, tees, eventOrRound.holesType) : teamScores.get(key);
            for (let i = holesRange.first; i < holesRange.last; i++) {
                if (!!score) {
                    compRow.push(score.gross[i]);
                } else {
                    compRow.push(0);
                }
            }
            compData.push(compRow);
        });
    } else if (golfers.size > 0 && hasIndividualCompetition) {
        const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
        golfers.forEach((contact: Contact, key: string) => {
            const compRow: Array<string | number> = [];
            compRow.push(fullName(contact) + (sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? ` (${contact.homeCourseOrCity})` : ''));
            const score = golferScores.get(key);
            for (let i = holesRange.first; i < holesRange.last; i++) {
                if (!!score) {
                    compRow.push(score.gross[i]);
                } else {
                    compRow.push(0);
                }
            }
            compData.push(compRow);
        });
    }
    const competitionsSkinsGames = competitions.filter(competition => isSkinsScoring(competition.scoring));
    const gender = genderFromEvent(eventOrRound);
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    competitionsSkinsGames.forEach(competition => {
        if (!mainCompetition) {
            return;
        }
        const isNet = isNetMode(competition.scoring.mode);
        const compGender = competition.competitionGender;
        const scores = Scoring.golferHoleScores(eventOrRound, competition, 0, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition);
        const tee = getTee(eventOrRound, mainCompetition, gender, undefined);
        const skinsInfo = getSkinsScores(eventOrRound, competition, scores, tee);
        skinsInfo.forEach(skin => {
            const firstContactHcp = skin.contacts[0];
            const winners = eventOrRound.teamSize > 1 ?
                (skin.contacts.length === 1 && firstContactHcp ? firstContactHcp.contacts.map(c => shortName(c).concat(sameNameGolfersIdsSet.has(c.id) && c.homeCourseOrCity ? `(${c.homeCourseOrCity})` : '')).join('+') : '')
                :
                (skin.contacts.length === 1 ? shortName(firstContactHcp.contacts[0]) : '');
            const skinsRow: Array<string> = [String(skin.hole + 1), 'Skins ' + (isNet ? 'net' : 'gross') + (!!compGender && compGender !== 'both' ? (' - ' + compGender) : ''), winners];
            compData.push(skinsRow);
        });
    });
    if (!competitionsSkinsGames.length) {
        compData.sort((row1, row2) => String(row1[0]).localeCompare(String(row2[0])));
    }
    compData.unshift(compHeader);
    return compData;
}
