import * as React from 'react';
import { FormControl, Typography } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogActions, DialogContent } from '@mui/material';
import { EventBase, Competition, WinnerInfo, isMainScoring, isNetMode, isGrossMode, CalculatedGrossNetWinnersMapsByFlight } from '../../../types/EventTypes';
import { getScoresWinners } from '../../Event';
import { processEnterKey } from '../../../util/react_utils';
import { range } from '../../../util/utility';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import * as Scoring from '../../../scoring/scoring';
import { styles } from '../../../styles';

function toStringWinnerInfo(w?: WinnerInfo) {
    return !w ? '' : w.mode + '-' + w.flight + '-' + w.contactId;
}

type SelectWinnersDialogProps = DialogProps & WithStyles<typeof styles> & {
    close: () => void;
    save: (selectedWinners: Map<string, Array<WinnerInfo>>) => void;
    eventOrRound: EventBase;
    competitions: Array<Competition>;
    calculatedScores: Map<string, CalculatedGrossNetWinnersMapsByFlight>;
};

type SelectWinnersDialogState = {
    selectedWinners: Map<string, Array<WinnerInfo>>;
    changes: number;
};

class SelectWinnersDialog extends React.Component<SelectWinnersDialogProps, SelectWinnersDialogState> {

    constructor(props: SelectWinnersDialogProps) {
        super(props);
        const { competitions, calculatedScores } = props;
        this.state = { selectedWinners: new Map<string, Array<WinnerInfo>>(), changes: 0 };
        competitions.forEach(competition => {
            let winners = getScoresWinners(competition, calculatedScores);
            this.state.selectedWinners.set(competition.id, winners);
        });
    }

    private save = () => this.props.save(this.state.selectedWinners);

    private selectWinner = (compId: string, winnerInfo: WinnerInfo) => {
        const { selectedWinners, changes } = this.state;
        const winners = (selectedWinners.get(compId) || []).filter(w => w.mode !== winnerInfo.mode || w.flight !== winnerInfo.flight);
        if (winnerInfo.contactId) {
            winners.push(winnerInfo);
        }
        selectedWinners.set(compId, winners);
        this.setState({ selectedWinners, changes: changes + 1 });
    }

    Tie = (params: { competition: Competition, flight: number, mode: 'gross' | 'net' }) => {
        const { classes, eventOrRound, calculatedScores } = this.props;
        const { competition, mode, flight } = params;
        const { selectedWinners } = this.state;
        const competitionScores = calculatedScores.get(competition.id);
        if (!competitionScores) {
            return null;
        }
        const winnerScores = mode === 'gross' ? competitionScores.competitionWinnersGross.get(flight) : competitionScores.competitionWinnersNet.get(flight);
        if (!winnerScores || winnerScores.length < 2) {
            return null;
        }
        const competitionName = Scoring.scoringName(competition, eventOrRound.eventGender, competition.competitionGender, false, flight, competition.flightsNaming, mode);
        const winners = selectedWinners.get(competition.id) || [];
        const current = toStringWinnerInfo(winners.find(w => w.mode === mode && w.flight === flight));
        return (
            <React.Fragment>
                <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'column' }}>
                    <Typography variant="subtitle2">{competitionName}</Typography>
                    {winnerScores.map(winnerScore => <TypedFormRadioLabel key={winnerScore.contactId}
                        value={toStringWinnerInfo({ mode, flight, contactId: winnerScore.contactId })}
                        label={winnerScore.names}
                        currentValue={current}
                        handleChange={_ => this.selectWinner(competition.id, { mode, flight, contactId: winnerScore.contactId })}
                        className={classes.radio} />)}
                    <TypedFormRadioLabel
                        value={''}
                        label={'Keep tie'}
                        currentValue={current}
                        handleChange={_ => this.selectWinner(competition.id, { mode, flight, contactId: '' })}
                        className={classes.radio} />
                </FormControl>
            </React.Fragment>
        );
    }

    CompetitionFlight = (competition: Competition, flight: number) => {
        return (
            <React.Fragment key={competition.id + '-' + flight}>
                {isNetMode(competition.scoring.mode) && this.Tie({ competition, mode: "net", flight })}
                {isGrossMode(competition.scoring.mode) && this.Tie({ competition, mode: "gross", flight })}
            </React.Fragment>
        );
    }

    Competition = (competition: Competition) => {
        if (!competition.flights) {
            return this.CompetitionFlight(competition, 0);
        } else {
            return (
                <React.Fragment key={competition.id}>
                    {range(1, competition.flights + 1).map(flight => this.CompetitionFlight(competition, flight))}
                </React.Fragment>
            );
        }
    }

    Competitions = () => {
        const { competitions } = this.props;
        return (
            <React.Fragment>
                {competitions.filter(c => isMainScoring(c.scoring)).map(this.Competition)}
            </React.Fragment>
        );
    }

    render() {
        const { close } = this.props;
        const { changes } = this.state;
        return (
            <XSMobileDialog open={this.props.open} onClose={close}>
                <DialogAppBar label={'Select winners'} close={close} />
                <DialogContent onKeyDown={uiEvent => processEnterKey(uiEvent, this.save)} >
                    <FormControl
                        variant="standard"
                        margin="dense"
                        fullWidth
                        style={{ flexDirection: 'column' }}>
                        <div>
                            A tie was detected in the competition(s) below. You have an option to select a winner by tie-breaker or leave it tied.
                            Automatic tie-break resolution will be added in the future. <a href={`https://support.golfpadgps.com/a/solutions/articles/6000253404`} target="_blank" rel="noopener noreferrer">Learn more &gt;</a>
                        </div>
                    </FormControl>
                    <this.Competitions />
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={close}>Cancel</AppButton>
                    <AppButton color="secondary" onClick={this.save} disabled={changes === 0}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(SelectWinnersDialog);
