import * as React from 'react';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import { FirebaseDocComponent, FirebaseDataComponent, withDataItemFromUrl } from '../common/WithData';
import { showAlert, showProgress, pushUrl } from '../redux/ReduxConfig';
import * as Backend from '../util/firebase';
import { Competition, Event, EventBase, EventMapping, Portal, Round, ContactInvite, fixLegacyTees, isRound, fixLegacyRounds, getRegistrationDate } from '../types/EventTypes';
import { eventBannerUrl, eventBadgeUrl, saveContactInvite } from '../event/Event';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import LinkTabs, { Tab } from '../event/tabs/common/LinkTabs';
import About from './About';
import Schedule from './Schedule';
import Standings from './Standings';
import { Container, Item } from '../common/Misc';
import { ErrorPage } from '../main/ErrorBoundary';
import { appStyles, styles } from '../styles';
import { Urls } from '../util/config';
import { dbgLog } from '../util/utility';
import { detectServiceWorkerUpdate } from 'src/main/SWRegistration';
import { AboutTabIcon, ScheduleTabIcon, StandingsTabIcon } from '../common/Icons';
import { eventInviteMappingDb } from '../util/firebase';
import { fullInviteName } from "../contact/Contact";
import { ACTION_INVITE_DECLINED } from "../event/tabs/golfers/list/InvitesList";

interface PublicProperties {
    event: Event;
    portal: Portal;
    withTabs?: string[];
}

const tabs: Array<Tab<string>> = [{
    id: 'about',
    label: 'About',
    getIcon: (active: boolean) => <AboutTabIcon active={active} />
}, {
    id: 'schedule',
    label: 'Schedule',
    getIcon: (active: boolean) => <ScheduleTabIcon active={active} />
}, {
    id: 'standings',
    label: 'Standings',
    getIcon: (active: boolean) => <StandingsTabIcon active={active} />
}];

export const Header = (props: PublicProperties) => {
    const { event, portal, withTabs } = props;
    const classes = appStyles();
    return (
        <>
            <div className={classes.publicBannerContainer}>
                <img className={classes.publicBanner} src={eventBannerUrl(event, portal)} alt="" />
                <img className={classes.publicBadge} src={eventBadgeUrl(event, portal)} alt="" />
                <Typography className={classes.publicName}>{event.name}</Typography>
            </div>
            <LinkTabs initial={(event.type === 'leaderboard' || event.leaderboard) ? 'standings' : 'about'}
                tabs={tabs.filter(tab => withTabs?.includes(tab.id))}
                pub={true} sticky onSelectedTab={_ => {
                }} classes={classes}
            />
        </>
    );
};

export const NoEvent = (props: { text?: string }) => {
    const { text } = props;
    const classes = appStyles();
    return (
        <div className={classes.centerOuter}>
            <div className={classes.centerInner}>
                <Container spacing={10} style={{ width: 600 }}>
                    <Item xs={12} placeCenter>
                        <img src={Urls.golfImage} style={{ height: 170 }} alt="" />
                    </Item>
                    <Item xs={12} placeCenter>{text || `Event not found`}</Item>
                    <Item xs={12} placeCenter><a href="/">Events Home</a></Item>
                </Container>
            </div>
        </div>
    );
};

type State = {
    eventId?: string;
    event?: Event,
    portal?: Portal
    invite?: ContactInvite;
    rounds: Array<Round>;
    selectedRound?: Round;
    competitionsMap: Map<string, Array<Competition>>;
    loadedRounds: number;
    loadedCompetitions: number;
    updateServiceWorker?: () => void;
    eventNotificationShown: boolean;
};

type Props = WithStyles<typeof styles> & RouteComponentProps<any>;

const Public = withRouter(class extends React.Component<Props, State> {
    state: State = {
        rounds: [],
        competitionsMap: new Map(),
        loadedRounds: 0,
        loadedCompetitions: 0,
        eventNotificationShown: false
    };

    private readonly fromStandingsInvite = window.location.pathname.endsWith('/standings/invite');

    private readonly fromAcceptInvite = window.location.pathname.includes('/invite-accept/');

    private readonly fromCancelInvite = window.location.pathname.includes('/invite-decline/');

    constructor(props: Props) {
        super(props);
        detectServiceWorkerUpdate(() => this.setState({
            updateServiceWorker: () => {
                dbgLog('Pub reload...');
                window.location.reload();
            }
        }));
    }

    async componentDidMount() {
        await this.loadEventData();
    }

    private loadEventData = async () => {
        const { match } = this.props;
        const hideProgress = showProgress();
        if (match.params.id) {
            const eventMappingId = !this.fromAcceptInvite && !this.fromCancelInvite ?
                await Backend.getEntity<EventMapping>(Backend.eventMappingDb, match.params.id) :
                await Backend.getEntity<EventMapping>(Backend.eventInviteMappingDb, match.params.id);
            this.setEventId(eventMappingId);
            if (this.fromAcceptInvite || this.fromCancelInvite) {
                const invite = await Backend.getEntity<ContactInvite>(Backend.golferInvitesDb(eventMappingId.eventId), match.params.id);
                this.setInvite(invite)
            }
        }
        hideProgress();
    }

    private setEventId = (em?: EventMapping) => this.setState({ eventId: em?.eventId || '' });
    private setPortal = (portal: Portal) => this.setState({ portal });
    private setInvite = (invite: ContactInvite) => this.setState({ invite });
    private setSelectedRound = (selectedRound?: Round) => this.setState({ selectedRound });

    private setEvent = (event: Event) => {
        this.setState({ event });
        if (event.type !== 'multiday') {
            this.setState({ loadedRounds: 1 });
        }
    }

    private onRounds = (rounds: Array<Round>) => {
        let { selectedRound, loadedRounds } = this.state;
        fixLegacyRounds(rounds).sort((a, b) => a.roundOrder - b.roundOrder);
        selectedRound = rounds.find(r => r.id === selectedRound?.id);
        if (!selectedRound && rounds.length > 0) {
            selectedRound = rounds[0];
        }
        this.setState({ rounds, selectedRound, loadedRounds: loadedRounds + 1 });
    }

    private fixLegacyTees = (baseEventId: string, competitions: Array<Competition>) => {
        const { event } = this.state;
        if (baseEventId === event?.id) {
            fixLegacyTees(event, competitions);
        }
    }

    private onCompetitions = (baseEvent: EventBase, competitions: Array<Competition>) => {
        const { competitionsMap, loadedCompetitions } = this.state;
        competitions.forEach(competition => competition.eventOrRoundId = baseEvent.id);
        if (isRound(baseEvent)) {
            competitions.forEach(competition => competition.roundOrder = baseEvent.roundOrder);
        }
        competitionsMap.set(baseEvent.id, competitions);
        this.setState({ competitionsMap, loadedCompetitions: loadedCompetitions + 1 }, () => this.fixLegacyTees(baseEvent.id, competitions));
    }

    private renderEvent = ({ event, portal, rounds, invite }: { event: Event, portal: Portal, rounds: Array<Round>, invite?: ContactInvite }) => {
        const { classes, match } = this.props;
        const { selectedRound, competitionsMap, loadedRounds, loadedCompetitions } = this.state;
        const parentUrl = match.url;
        match.params.id = 0;
        return (
            <div className={classes.pubRoot}>
                <Header event={event} portal={portal} withTabs={
                    (event.type === 'leaderboard' || event.leaderboard) ? ['standings',] : ['about', 'schedule', 'standings',]
                } />
                <Switch>
                    {event.type !== 'leaderboard' &&
                        <Route path={`${parentUrl}/about`} render={() =>
                            <About
                                event={event}
                                portal={portal}
                                rounds={rounds}
                                invite={invite}
                                decline={this.fromCancelInvite}
                                competitionsMap={competitionsMap}
                            />}
                        />}
                    {event.type !== 'leaderboard' &&
                        <Route path={`${parentUrl}/schedule`} render={() =>
                            <Schedule
                                event={event}
                                rounds={rounds}
                                selectedRound={selectedRound}
                                setSelectedRound={this.setSelectedRound}
                            />}
                        />}
                    <Route path={`${parentUrl}/standings`} render={() =>
                        <Standings
                            event={event}
                            rounds={rounds}
                            competitionsMap={competitionsMap}
                            loadedRounds={loadedRounds}
                            loadedCompetitions={loadedCompetitions}
                            selectedRound={selectedRound}
                            setSelectedRound={this.setSelectedRound}
                        />}
                    />
                </Switch>
            </div>
        );
    }

    private readonly landingPageLink = <a className={this.props.classes.linkBlue} href={Urls.landingPageLink}
        rel='noopener noreferrer'>Learn more</a>;

    private readonly eventsInvitationInfoElement = <a className={this.props.classes.linkBlue}
        href={Urls.landingPageLink} rel='noopener noreferrer'>Learn
        more</a>;

    private readonly linkIsNotValidAnyMoreFragment =
        <>
            <span
                className={this.props.classes.spanStyle}>This link is not valid any more.</span>
            <br />
            <span className={this.props.classes.spanStyle}>{this.eventsInvitationInfoElement}&nbsp;about Events invites in Golf Pad.</span>
        </>;

    private readonly alertFragment =
        <>
            <span
                className={this.props.classes.spanStyle}>Trying to join an event? Please open the link on your phone. </span>
            <br />
            <span className={this.props.classes.spanStyle}>{this.eventsInvitationInfoElement}&nbsp;about Events invites in Golf Pad.</span>
        </>;

    private readonly inviteExpiredAlertFragment =
        <>
            <span
                className={this.props.classes.spanStyle}>The deadline has past for this event. Please contact the event organizer.</span>
        </>;

    private getCancelInviteFragment = (invite: ContactInvite) => {
        return <>
            <span
                className={this.props.classes.spanStyle}>Invite to {fullInviteName(invite)} has been canceled.</span>
            <br />
            <span className={this.props.classes.spanStyle}>{this.eventsInvitationInfoElement}&nbsp;about Golf Pad Events.</span>
        </>;
    }

    render() {
        if (this.state.updateServiceWorker) {
            this.state.updateServiceWorker();
        }
        const { eventId } = this.state;
        dbgLog(`eventId=${eventId}`);
        if (eventId === undefined) {
            return <NoEvent text="Loading" />;
        }
        if (eventId === '') {
            const { match } = this.props;
            const err = `Event with id "${match.params.id}" not found!`;
            return <ErrorPage error={err} errorInfo={""} />;
        }
        const { event, rounds, portal, invite, eventNotificationShown } = this.state;
        dbgLog(event);
        if (event != null) {
            if (event.handicapSystem === 'CONGU') {
                event.handicapSystem = 'WHS_UK';
            } else if (event.handicapSystem === 'GA') {
                event.handicapSystem = 'WHS_AU';
            } else if (event.handicapSystem !== 'WHS' && event.handicapSystem !== 'WHS_UK' && event.handicapSystem !== 'WHS_AU' && event.handicapSystem !== 'SMPLFD') {
                event.handicapSystem = 'WHS';
            }
        }
        const { classes } = this.props;
        let content;
        if (event && portal && event.exists) {
            content = !event.deleted ?
                <this.renderEvent event={event} portal={portal} invite={invite} rounds={rounds} /> :
                <div className={classes.midScreenRoot}>
                    <h1>The event you are trying to access has been deleted.</h1>
                    <br />
                    <p>Click here to {this.landingPageLink} about Golf Pad Events.</p>
                </div>;
        } else {
            content = <NoEvent text="Loading" />;
        }
        if (!event?.leaderboard && !eventNotificationShown && this.fromStandingsInvite) {
            showAlert(this.alertFragment, [{
                title: 'CLOSE',
                color: 'primary',
                action: () => this.setState({ eventNotificationShown: true })
            },]);
        }
        if (event && !!invite && !eventNotificationShown) {
            if (getRegistrationDate(event) > Date.now()) {
                if (this.fromCancelInvite || this.fromAcceptInvite) {
                    if (invite.hidden || (invite.inviteStatus !== 'invite_sent' && invite.inviteStatus !== 'invite_resent')) {
                        showAlert(this.linkIsNotValidAnyMoreFragment, [{
                            title: 'CLOSE',
                            color: 'primary',
                            action: () => this.setState({ eventNotificationShown: true, invite: undefined }, () => pushUrl(`/event/${event.publicId}/about`))
                        },]);
                    } else if (this.fromCancelInvite) {
                        saveContactInvite(event, { ...invite, inviteStatus: "declined", statusDate: Date.now() }, ACTION_INVITE_DECLINED)
                            .then(() => this.setState({ invite: undefined }, () => {
                                showAlert(this.getCancelInviteFragment(invite), [{
                                    title: 'Ok',
                                    color: 'primary',
                                    action: () => this.setState({ eventNotificationShown: true, invite: undefined }, () => pushUrl(`/event/${event.publicId}/about`))
                                },]);
                            }));
                    }
                }
            } else {
                showAlert(this.inviteExpiredAlertFragment, [{
                    title: 'CLOSE',
                    color: 'primary',
                    action: () => this.setState({ eventNotificationShown: true })
                },]);
            }
        }
        const multiday = event?.type === 'multiday';
        return <>
            {content}
            <FirebaseDocComponent onDoc={doc => this.setEvent(Backend.fromEntity<Event>(doc))}
                docReference={Backend.eventFields(eventId)} />
            <FirebaseDocComponent onDoc={doc => this.setPortal(Backend.fromEntity<Portal>(doc))}
                docReference={Backend.portalFields(eventId)} />
            {event != null && <>
                {multiday && <FirebaseDataComponent
                    name="rounds"
                    query={Backend.eventRoundsQuery(event.id)}
                    onData={this.onRounds} />}
                {(multiday ? rounds : [event]).map(round => <FirebaseDataComponent<Competition>
                    key={round.id}
                    name="competitions"
                    queryPath={round.id}
                    query={Backend.eventCompetitionsQuery(round.id)}
                    onData={competitions => this.onCompetitions(round, competitions)} />)}
            </>}
        </>;
    }
});

export default withDataItemFromUrl(Backend.eventMappingDb)(withStyles(styles)(Public));

export const PublicConfirmation = withStyles(styles)(withDataItemFromUrl<EventMapping, {}>(eventInviteMappingDb)(Public));
